* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  margin: 0;
  padding: 0;
  width: 100%;
}
.opacity-remover {
  opacity: 1;
  position: relative;
}

.opacity-remover h1 {
  text-align: center;
}
